<template>
  <div>
    <div class="main-content">
      <div class="row px-3" style="font-weight: 700;">
        <div class="col-md-3 p-1" v-for="(count, index) in counter" :key="index">
          <router-link class="card card-home p-1" style="min-height: 15vh; cursor: pointer;" :to="count.url" v-if="$store.state.user.perfil_id >= 6">
            <span :class="'b-avatar badge-light-' + color[index % color.length] + ' rounded-circle'">
              <span class="b-avatar-custom">
                <i :class="count.icons"></i>
              </span>
            </span>
            <h3 class="text-dark mt-1"><b>{{count.qtd}}</b></h3>
            <span class="text-secondary" style="font-size: 2vh;">{{count.name}}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      dashboard: [],
      counter: {},
      color: [
        'primary',
        'success',
        'danger',
        'warning'
      ],
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    counters: function(){
      const self = this;
      const api = self.$store.state.api + "counter/";

      axios
        .get(api)
        .then((response) => {
          self.counter = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;

    self.counters();
  },
  created() {},
};
</script>

<style>
i{
  font-size: 13pt;
}

.card-home:hover{
  box-shadow: 3px 3px #ddd;
  transform: scale(1.05);
}
</style>
